@tailwind base;
@tailwind components;
@tailwind utilities;

body, html, .map {
    height: 100vh;
    min-width: 100%;
}

.leaflet-container {
    position: relative;
    height: 100%;
}

.marker-cluster-small div {
    background-color: rgba(20, 87, 121, 0.6) !important;
}

.marker-cluster-small {
    background-color: rgba(18, 103, 143, 0.6) !important;
}

.marker-cluster-small div span {
    color: white;
    font-weight: bold;
}

.marker-cluster-medium div {
    background-color: rgba(20, 87, 121, 0.6) !important;
}

.marker-cluster-medium {
    background-color: rgba(18, 103, 143, 0.6) !important;
}

.marker-cluster-medium div span {
    color: white;
    font-weight: bold;
}

.marker-cluster-large div {
    background-color: rgba(20, 87, 121, 0.6) !important;
}

.marker-cluster-large {
    background-color: rgba(18, 103, 143, 0.6) !important;
}

.marker-cluster-large div span {
    color: white;
    font-weight: bold;
}

.leaflet-control-attribution {
    display: none;
}
